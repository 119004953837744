/**
 * 定数定義
 */
export const CONSTANTS = {
    /**
     * アラートに関する定数
     */
    ALERT_MESSAGE: {
        // 参照画面
        REF01: {
            severity: "warning",
            message: "表示された情報の取り扱いには注意してください。",
        },
        // 要求画面
        REQ01: {
            severity: "warning",
            message: "承認が必要です。承認要求を送信してください。",
        },
        // 承認画面(保留中,要求者向け)
        APR01: {
            severity: "info",
            message: "承認要求は保留中です。",
        },
        // 承認画面(保留中,承認者向け)
        APR02: {
            severity: "info",
            message: "承認要求に応答してください。",
        },
        // 承認画面(承認)
        APR11: {
            severity: "success",
            message: "承認要求が承認されました。",
        },
        // 承認画面(拒否)
        APR21: {
            severity: "success",
            message: "承認要求が拒否されました。",
        },
    },

    /**
     * 承認に関する定数
     */
    APPROVAL: {
        /**
         * 操作種別
         * - ACCEPT: 承諾する
         * - REFUSE: 拒否する
         */
        ACTION_TYPE: {
            APPROVE: "Approve",
            REJECT: "Reject",
        },

        /**
         * 承認状態
         * - NONE: 未承認（承認要求も発行されていない状態）
         * - WAITING: 承認待ち
         * - ACCEPTED: 承認された
         * - REFUSED: 拒否された
         * - DISABLED: 無効化された
         */
        STATUS: {
            NONE: null,
            PENDING: "Pending",
            APPROVED: "Approved",
            REJECTED: "Rejected",
            DISABLED: "Disabled",
        },
    }
};