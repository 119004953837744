import { Alert } from '@mui/material';
import { useEffect, useState } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import { protectedResources } from "../authConfig";

/**
 * 指定されたロケーションを取得します。
 * - ID
 * 
 * @param {*} props 
 * @returns 
 */
export const LocationGetResource = (props) => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [location, setLocation] = useState(null);

    useEffect(() => {
        if (account && inProgress === "none") {
            instance.acquireTokenSilent({
                scopes: protectedResources.locationApi.scopes,
                account: account
            }).then(async (res) => {
                const tokenResponse = res;

                // GetLocation API を呼び出して、ロケーションを取得します。
                await getLocation(tokenResponse.accessToken, props.id)
                    .then((res) => {
                        setLocation(res);
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }).catch((error) => {
                console.log(error);
            });
        }
    }, [account, inProgress, instance]);

    return (location);

    /**
     * 指定されたロケーションを取得します。
     * @param {*} accessToken 
     * @param {*} locationId 
     * @returns 
     */
    async function getLocation(accessToken, locationId) {
        return new Promise(async (resolve, reject) => {
            // API実行の準備
            const apiEndpoint = `${protectedResources.locationApi.endpoint}/${locationId}`
            const custom_authorization_header = process.env["REACT_APP_AAD_APP_CUSTOM_AUTHORIZATION_HEADER"];
            const reqHeaders = new Headers();
            reqHeaders.append('Content-Type', 'application/json');
            reqHeaders.append(custom_authorization_header, 'Bearer ' + accessToken);
            const options = {
                method: 'GET',
                headers: reqHeaders,
            };

            // API実行
            await fetch(apiEndpoint, options)
                .then((res) => res.json())
                .then((json) => {
                    resolve(json);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    };
} 