import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Chip } from '@mui/material';

import Navbar from "react-bootstrap/Navbar"
import Button from "react-bootstrap/Button";

import { DropdownMenu } from "./DropdownMenu";
import { loginRequest } from "../authConfig";

export const NavigationBar = () => {

    const { instance } = useMsal();

    /**
     * Most applications will need to conditionally render certain components based on whether a user is signed in or not. 
     * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will 
     * only render their children if a user is authenticated or unauthenticated, respectively.
     */
    return (
        <>
            <Navbar bg="primary" variant="dark">
                <a className="navbar-brand" href="/">
                    <strong>Torch</strong>
                    {window.location.hostname !== process.env["REACT_APP_AAD_APP_PRODUCTION_HOSTNAME"]
                        ? window.location.hostname == "localhost"
                            ? <> <Chip label="localhost" size="small" color="info" /></>
                            : <> <Chip label="preview" size="small" color="warning" /></>
                        : <></>
                    }
                </a>
                <AuthenticatedTemplate>
                    <div style={{ flexGrow: 1 }}></div>
                    <DropdownMenu />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <div style={{ flexGrow: 1 }}></div>
                    <Button variant="warning" onClick={() => instance.loginRedirect(loginRequest)}>Sign In</Button>
                </UnauthenticatedTemplate>
            </Navbar>
        </>
    );
};