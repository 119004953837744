import { Alert } from '@mui/material';
import { LocationGetResource } from './LocationGetResource';

/**
 * 指定されたロケーションにおける以下の情報を表示します。
 * - ID
 * - ラベル
 * - 説明
 * 
 * @param {*} props 
 * @returns 
 */
export const LocationDisplayList = (props) => {
    // ロケーションの取得
    const location = LocationGetResource(props);
    return (
        <Alert icon={false} severity="info" >
            <ul>
                <li><strong>ID: </strong>{location ? location.id : "---"}</li>
                <li><strong>ラベル: </strong>{location ? location.value.label : "---"}</li>
                <li><strong>説明: </strong>{location ? location.value.description : "---"}</li>
            </ul>
        </Alert>
    );
} 