import { useEffect, useState } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import { protectedResources } from "../authConfig";
import { callOwnApiWithToken } from "../fetch";
import { useNavigate, useSearchParams } from "react-router-dom";

export const SubmitContent = () => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [secretData] = useState(null);
    const [searchParams] = useSearchParams();
    const queryString = searchParams.get('q');
    const [isLoading, setLoading] = useState(true);
    const navigate = useNavigate();
    // 承認の状態を管理するstate
    const [approval, setApproval] = useState(null);

    useEffect(() => {
        if (account && inProgress === "none" && !secretData) {
            instance.acquireTokenSilent({
                scopes: protectedResources.approvalApi.scopes,
                account: account
            }).then((response) => {
                // InvokeRefs API を呼び出して、指示対象に応じた振る舞いをさせます。
                callOwnApiWithToken(response.accessToken, protectedResources.approvalApi.endpoint + "/" + queryString)
                    .then((res) => {
                        console.log(res);
                        const newApproval = res;
                        setApproval(newApproval);
                        setLoading(false)
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }).catch((error) => {
                console.log(error);
            });
        }
    }, [account, inProgress, instance]);

    return (
        <>
            {
                isLoading ? (
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border text-info" role="status" />
                    </div>
                ) : (
                    navigate(`/approval?q=${approval.LocationId}&p=${approval.partitionKey}&r=${approval.rowKey}`)
                )
            }
        </>
    )
}


export const Submit = () => {
    return (
        <SubmitContent />
    )
};