import { Container } from '@mui/material';
import { CONSTANTS } from '../constants';

/**
 * 指定された内容で承認データを表示します。
 * 受け取った props に承認データ(approval)が指定されていない場合は、何も表示しません。
 * 
 * @param {object} props - コンポーネントに渡されるプロパティ
 * @param {string} props.approval - 承認データ
 * @param {string} props.isValid - 有効フラグ
 * @returns {JSX.Element}
 */
export const DiscoveryDisplayApproval = (props) => {
    return (
        <>
            {
                props.approval &&
                <Container maxWidth="md">
                    <ul>
                        <li><b>要求日時・要求者</b></li>
                        <li><i><mark>{new Date(props.approval.requestTime).toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" })}</mark></i></li>
                        <li><i><mark>{props.approval.requester.name} ({props.approval.requester.upn})</mark></i></li>
                        <li><b>備考・摘要</b></li>
                        {(props.approval.remarks)
                            ? <li><i><mark>{props.approval.remarks}</mark></i></li>
                            : <li><i><mark>---</mark></i></li>
                        }
                        <li><b>応答期限</b></li>
                        <li><i><mark className={new Date().toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" }) <= new Date(props.approval.approvalDeadline).toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" }) ? "" : "mark-invalid"}>{new Date(props.approval.approvalDeadline).toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" })}</mark></i></li>
                        <li><b>応答日時・応答者</b></li>
                        {(props.approval.lastApproveTime)
                            ? <li><i><mark>{new Date(props.approval.lastApproveTime).toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" })}</mark></i></li>
                            : <li><i><mark>---</mark></i></li>
                        }
                        {(props.approval.lastApprover.name && props.approval.lastApprover.upn)
                            ? <li><i><mark>{props.approval.lastApprover.name} ({props.approval.lastApprover.upn})</mark></i></li>
                            : <li><i><mark>---</mark></i></li>
                        }
                        <li><b>承認有効期限</b></li>
                        {(props.approval.expirationTime == null || props.approval.status == CONSTANTS.APPROVAL.STATUS.REJECTED)
                            ? <li><i><mark>---</mark></i></li>
                            : <li><i><mark className={new Date().toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" }) <= new Date(props.approval.expirationTime).toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" }) ? "" : "mark-invalid"}>{new Date(props.approval.expirationTime).toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" })}</mark></i></li>
                        }
                    </ul>
                    <hr></hr>
                </Container>
            }
        </>
    );
} 