import "../styles/App.css";
import parse from 'html-react-parser';
import { useSearchParams, useNavigate } from "react-router-dom";
import { DiscoveryDisplayNotification } from "./DiscoveryDisplayNotification";
import { DiscoveryInputMemo } from "./DiscoveryInputMemo";
import { CONSTANTS } from '../constants';
import { useEffect, useState } from "react";
import { DiscoverySubmitButton } from "./DiscoverySubmitButton";
import { useMsal, useAccount } from "@azure/msal-react";
import { protectedResources } from "../authConfig";

export const DataDisplay = (props) => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const queryString = searchParams.get('q');
    // 通知に関する定数
    const { REF01, REQ01 } = CONSTANTS.ALERT_MESSAGE;
    // 入力されたテキストを管理するstate
    const [inputText, setInputText] = useState("");
    const [tokenResponse, setTokenResponse] = useState(null);

    // テキストフィールドの値が変更されたときに呼び出される関数
    const handleTextFieldChange = (event) => {
        // stateを更新する
        setInputText(event.target.value);
    };

    // 送信ボタンがクリックされたときに呼び出される関数
    const handleSubmitButtonClick = async () => {
        // Approval API を呼び出して、承認リソースを作成します。
        const apiEndpoint = `${protectedResources.approvalApi.endpoint}/${queryString}`;
        const custom_authorization_header = process.env["REACT_APP_AAD_APP_CUSTOM_AUTHORIZATION_HEADER"];
        const reqHeaders = new Headers();
        reqHeaders.append('Content-Type', 'application/json');
        reqHeaders.append(custom_authorization_header, 'Bearer ' + tokenResponse.accessToken);
        const options = {
            method: 'POST',
            headers: reqHeaders,
            body: inputText
        };

        // API実行
        await fetch(apiEndpoint, options)
            .then((res) => res.json())
            .then((json) => {
                navigate(`/approval?q=${json.LocationId}&p=${json.partitionKey}&r=${json.rowKey}`)
                return (json);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(() => {
        if (account && inProgress === "none") {
            instance.acquireTokenSilent({
                scopes: protectedResources.approvalApi.scopes,
                account: account
            }).then(async (res) => {
                console.log(res);
                const newTokenResponse = res;
                setTokenResponse(newTokenResponse);
            }).catch((error) => {
                console.log(error);
            });
        }
    }, []);

    // value がオブジェクト、かつキーに 'hasApprove' が存在しない場合、value の内容をテーブル形式で表示する。
    if ((typeof props.secretData.value == "object") && !('hasApprove' in props.secretData.value)) {
        const tableRows = parse(tableRowsCreate(props.secretData.value, 0));
        console.log(tableRows);

        return (
            <>
                <div className="notification-area-div">
                    <DiscoveryDisplayNotification severity="warning" message="表示されたデータには機密情報が含まれますので、取扱いについては充分注意してください。他者との共有は厳禁です。" />
                </div>
                <div className="data-area-div">
                    <table>
                        <thead>
                        </thead>
                        <tbody>
                            {tableRows}
                        </tbody>
                    </table>
                </div>
            </>
        );
    } else {
        if ((typeof props.secretData.value == "object") && ('hasApprove' in props.secretData.value)) {
            return (
                <>
                    <div className="notification-area-div">
                        <DiscoveryDisplayNotification severity={REQ01.severity} message={REQ01.message} />
                    </div>
                    {(props.secretData.value.hasApprove && props.secretData.value.code == null)
                        ?
                        <>
                            <div className="data-area-div">
                                <table>
                                    <thead>
                                        <tr><th>承認者</th></tr>
                                    </thead>
                                    <tbody>
                                        {props.secretData.value.location.approvers.map((approver, index) => (
                                            <tr key={index}><td><i><mark>{approver.name}({approver.upn})</mark></i></td></tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className="memo-area-div">
                                <DiscoveryInputMemo value={inputText} onChange={handleTextFieldChange} />
                            </div>

                            <div className="button-area-div">
                                <DiscoverySubmitButton locationId={queryString} memo={inputText} onClick={handleSubmitButtonClick} />
                            </div>
                        </>
                        :
                        navigate(`/approval?q=${props.secretData.value.approval.LocationId}&p=${props.secretData.value.approval.partitionKey}&r=${props.secretData.value.approval.rowKey}`)
                    }
                </>
            );
        } else {
            return (
                <>
                    <div className="notification-area-div">
                        <DiscoveryDisplayNotification severity="error" message={props.secretData.value} />
                    </div>
                </>
            );
        }
    }

    /**
     * JSONデータからテーブルの本体要素を構成します。
     * @param {*} json
     * @param {*} count 呼び出し回数
     * @returns 
     */
    function tableRowsCreate(json, count) {
        let result = [];
        // 呼び出し回数をインデントに設定する
        const indent = count;
        for (var key in json) {
            if (typeof json[key] == 'object') {
                if (Array.isArray(json[key])) {
                    // 配列の場合、key は要素の数だけ行を結合する
                    let arrayCounter = 0;
                    json[key].forEach(function (item) {
                        if (arrayCounter == 0) {
                            // key + value
                            result.push('<tr><td rowspan=' + json[key].length + ' style="text-indent: ' + indent + 'em;"><b>' + key + '</b></td><td><i><mark>' + item + '</mark></i></td>');
                        } else {
                            // value のみ
                            result.push('<tr><td><i><mark>' + item + '</mark></i></td>');
                        }
                        arrayCounter++;
                    });
                    result.push('</tr>')
                } else {
                    // 連想配列はそのまま再帰呼び出し
                    result.push('<tr><td colspan="2" style="text-indent: ' + indent + 'em;"><b>' + key + '</b></td></tr>');
                    result.push(...tableRowsCreate(json[key], count + 1));
                }
            } else {
                // 配列や連想配列でなければキーの値を表示
                result.push('<tr><td style="text-indent: ' + indent + 'em;"><b>' + key + '</b></td><td><i><mark>' + json[key] + '</mark></i></td></tr>');
            }
        }
        // 配列の要素を連結して返却
        return result.join('');
    }
}