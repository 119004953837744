import { TextField } from '@mui/material';

/**
 * テキストフィールドを表示させ、入力されたテキストを返却します。
 * なお、テキストの入力は任意(optional)です。
 * 
 * @param {*} props 
 * @returns 
 */
export const DiscoveryInputMemo = (props) => {
    const CONST_MAX_LENGTH = 100;
    const CONST_HELPER_TEXT = `※${CONST_MAX_LENGTH}文字以内で記述してください。(改行も1文字に含まれます)`;
    return (
        <TextField
            id="outlined-helperText"
            label="「備考・摘要」入力欄（任意）"
            multiline
            rows={5}
            placeholder="（要求事由など、要求に含めたいことを記述できます）"
            helperText={CONST_HELPER_TEXT}
            fullWidth
            inputProps={{ maxLength: CONST_MAX_LENGTH }}
            value={props.value}
            onChange={props.onChange}
        />
    );
} 