import { NavigationBar } from "./NavigationBar";
import { useSearchParams } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { LocationDisplayList } from "./LocationDisplayList";

export const PageLayout = (props) => {
    const [searchParams] = useSearchParams();
    const queryString = searchParams.get('q');

    /**
     * Most applications will need to conditionally render certain components based on whether a user is signed in or not. 
     * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will 
     * only render their children if a user is authenticated or unauthenticated, respectively.
     */
    return (
        <>
            <NavigationBar />
            <AuthenticatedTemplate>
                {(queryString && props.children)
                    ? <>
                        <LocationDisplayList id={queryString} />
                        {props.children}</>
                    : <>
                        <h5><center>Welcome to the Torch</center></h5></>
                }
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <h5><center>Please Sign In</center></h5>
            </UnauthenticatedTemplate>
            <br />
        </>
    );

};