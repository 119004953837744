import { Alert, AlertTitle, Container } from '@mui/material';

/**
 * 指定された内容で通知メッセージを表示します。
 * 受け取った props に重大度(severity)とメッセージ(message)が指定されていない場合は、何も表示しません。
 * 
 * @param {object} props - コンポーネントに渡されるプロパティ
 * @param {string} props.severity - 重大度
 * @param {string} props.title - タイトル
 * @param {string} props.message - メッセージ
 * @returns {JSX.Element}
 */
export const DiscoveryDisplayNotification = (props) => {
    return (
        <>
            <Container maxWidth="md">
                {props.severity && props.title &&
                    <Alert severity={props.severity} >
                        <AlertTitle><strong>{props.title}</strong></AlertTitle>
                        {props.message}
                    </Alert>
                }
            </Container>
        </>
    );
} 