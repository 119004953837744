import { useEffect, useState } from "react";
import { useSearchParams } from 'react-router-dom';

import { MsalAuthenticationTemplate, useMsal, useAccount } from "@azure/msal-react";
import { InteractionRequiredAuthError, InteractionType } from "@azure/msal-browser";

import { loginRequest, protectedResources } from "../authConfig";
import { callOwnApiWithToken } from "../fetch";
import { DataDisplay } from "../components/DataDisplay";
import { appInsights } from '../ApplicationInsightsService';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

const SecretContent = () => {
    /**
     * useMsal is hook that returns the PublicClientApplication instance, 
     * an array of all accounts currently signed in and an inProgress value 
     * that tells you what msal is currently doing. For more, visit: 
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
     */
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [secretData, setSecretData] = useState(null);
    const [searchParams] = useSearchParams();
    const queryString = searchParams.get('q');
    const [isLoading, setLoading] = useState(true);
    const componentName = "SecretContent";

    const traceProperties = {
        domain: window.location.hostname,
        account: {
            id: (account.localAccountId) ? account.localAccountId : null,
            name: (account.name) ? account.name : null
        },
        parameter: {
            locationId: queryString,
        }
    }

    const trackTrace = (message, level) => {
        appInsights.trackTrace({
            message: message,
            severityLevel: level,
            properties: traceProperties
        });
    }

    trackTrace(`[${componentName}]: Component has been invoked.`, SeverityLevel.Verbose);

    useEffect(() => {
        trackTrace(`[${componentName}][useEffect]: hook has been invoked.`, SeverityLevel.Verbose);
        if (account && inProgress === "none" && !secretData) {
            instance.acquireTokenSilent({
                scopes: protectedResources.locationApi.scopes,
                account: account
            }).then((response) => {
                // InvokeRefs API を呼び出して、指示対象に応じた振る舞いをさせます。
                callOwnApiWithToken(response.accessToken, protectedResources.locationApi.endpoint + "/" + queryString + "/invokeRefs")
                    .then((invokeRefsResult) => {
                        setLoading(false)
                        // 種別が `SimpleRedirect` の場合、valueに設定されているURLへリダイレクトします。
                        if (invokeRefsResult.referenceType == "SimpleRedirect") {
                            window.location.replace(invokeRefsResult.value);
                        } else {
                            setSecretData(invokeRefsResult)
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }).catch((e) => {
                // in case if silent token acquisition fails, fallback to an interactive method
                trackTrace(`[${componentName}][useEffect]: Silent token request failed. error[${JSON.stringify(e)}]`, SeverityLevel.Error);
                if (e instanceof InteractionRequiredAuthError) {
                    trackTrace(`[${componentName}][useEffect]: A server error requiring an interactive call has occurred and a redirect is used to retrieve the token.`, SeverityLevel.Information);
                    instance.acquireTokenRedirect({
                        scopes: protectedResources.locationApi.scopes,
                        account: account
                    }).then(() => {
                        trackTrace(`[${componentName}][useEffect]: The acquireTokenRedirect method succeeded.`, SeverityLevel.Verbose);
                    }).catch((e) => {
                        trackTrace(`[${componentName}][useEffect]: The acquireTokenRedirect method failed. error[${JSON.stringify(e)}]`, SeverityLevel.Error);
                    });
                }
            });
        }
    }, [account, inProgress, instance]);

    return (
        <>
            {
                isLoading ? (
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border text-info" role="status" />
                    </div>
                ) : secretData ? (
                    <DataDisplay secretData={secretData} />
                ) : (
                    null
                )
            }
        </>
    );
};

/**
 * The `MsalAuthenticationTemplate` component will render its children if a user is authenticated 
 * or attempt to sign a user in. Just provide it with the interaction type you would like to use 
 * (redirect or popup) and optionally a [request object](https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md)
 * to be passed to the login API, a component to display while authentication is in progress or a component to display if an error occurs. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const Secret = () => {
    const authRequest = {
        ...loginRequest
    };

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
        >
            <SecretContent />
        </MsalAuthenticationTemplate>
    )
};