import { useMsal, useAccount } from "@azure/msal-react";
import Dropdown from "react-bootstrap/Dropdown";

export const DropdownMenu = () => {

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    if (account) {
        return (
            <>
                <Dropdown>
                    <Dropdown.Toggle variant="info" id="dropdown-basic">
                        {account.name}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                    <Dropdown.Item onClick={()=>instance.logoutRedirect({postLogoutRedirectUri: "/"})}>Sign Out</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </>
        );
    } else {
        return (<></>);
    }
};