import { Button, Stack } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { CONSTANTS } from '../constants';

/**
 * 承認に関する以下のボタンを表示します。
 * 
 * - "承認"ボタン
 *   - クリック時の動作: "承認(Approve)" として、コールバック関数を実行します。
 *   - props が以下のいずれかに当てはまる場合、disabled が有効となりボタンが非活性になります。
 *     - 有効(isValid) が false
 *     - ステータス(status) が "Approved"
 * 
 * - "拒否"ボタン
 *   - クリック時の動作: "拒否(Reject)" として、コールバック関数を実行します。
 *   - props が以下のいずれかに当てはまる場合、disabled が有効となりボタンが非活性になります。
 *     - 有効(isValid) が false
 *     - ステータス(status) が "Rejected"
 * 
 * - "参照"ボタン
 *   - クリック時の動作: 参照画面を表示します。
 * 
 * @param {object} props - コンポーネントに渡されるプロパティ
 * @param {boolean} props.isApprover - 承認者フラグ(true:承認者)
 * @param {boolean} props.isValid - 有効フラグ(true:有効)
 * @param {object} props.approval - 承認リソース
 * @param {function} props.handleClick - コールバック関数
 * @returns {JSX.Element}
 * 
 */
export const DiscoveryApproveButton = (props) => {
    const navigate = useNavigate();
    const { ACTION_TYPE, STATUS } = CONSTANTS.APPROVAL;
    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Stack spacing={2} direction="row">
                {props.isApprover &&
                    <>
                        <Button disabled={!props.isValid || props.approval.status == STATUS.APPROVED} variant="contained" color="success" size="large" onClick={() => props.handleClick(ACTION_TYPE.APPROVE)}>承認</Button>
                        <Button disabled={!props.isValid || props.approval.status == STATUS.REJECTED} variant="outlined" color="error" size="large" onClick={() => props.handleClick(ACTION_TYPE.REJECT)}>拒否</Button>
                    </>
                }
                <Button disabled={props.isValid && props.approval.status == STATUS.PENDING} variant="text" color="info" size="large" onClick={() => navigate(`/?q=${props.locationId}`)}>参照</Button>
            </Stack>
        </div>
    );
}
