import { Backdrop, CircularProgress } from "@mui/material";

/**
 * オーバーレイスピナーを表示するためのコンポーネント
 * @returns 
 */
export const OverlaySpinner = () => {
    return (
        <Backdrop sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};
