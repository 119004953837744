import { Button, Stack } from '@mui/material';
import { useNavigate } from "react-router-dom";

/**
 * 要求画面におけるボタンを表示します。
 * - 送信
 * - キャンセル
 * 
 * @param {object} props - コンポーネントに渡されるプロパティ
 * @param {function} props.onClick - コールバック関数
 * @returns 
 */
export const DiscoverySubmitButton = (props) => {
    const navigate = useNavigate();
    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Stack spacing={2} direction="row">
                <Button variant="contained" color="primary" size="large" onClick={props.onClick}>送信</Button>
                <Button variant="outlined" color="secondary" size="large" onClick={() => navigate(`/`)}>キャンセル</Button>
            </Stack>
        </div>
    );
} 